import type {
  TListJobApplicationQuery,
  TListJobApplicationResponse,
  TApplicantJobApplication,
  TLmsJobApplication,
  TCreateJobApplicationPayload
} from '@tl-lms/types/job-application'
import type { TNAPIBody } from '~/utils/napi-helpers'

function applicantListJobApplication(
  query: TListJobApplicationQuery | Ref<TListJobApplicationQuery>,
  asyncDataOptions?: Object,
  oFetchOptions?: Object
) {
  const { fetchOnce } = useAuthFetch()

  const { data: user } = useAuth()
  if (!user.value) return null
  const userIdSlug = user.value.id_slug

  async function fetchHandler() {
    const _query = removeFalsyValuesFromPayload(query, ['*'])
    return await fetchOnce<TApplicantJobApplication>(
      `/api/students/${userIdSlug}/applications/`,
      'GET',
      undefined,
      _query,
      oFetchOptions
    )
  }

  return useAsyncData<TApplicantJobApplication>(
    `job-student-apps-list-${JSON.stringify(query)}`,
    fetchHandler,
    {
      // only watch if query is a ref
      watch: isRef(query) || isReactive(query) ? [query] : undefined,
      ...asyncDataOptions
    }
  )
}

function adminListJobApplication(
  jobIdSlug: string,
  query: TListJobApplicationQuery | Ref<TListJobApplicationQuery>,
  asyncDataOptions?: Object,
  oFetchOptions?: Object
) {
  const { fetchOnce } = useAuthFetch()

  async function fetchHandler() {
    const _query = removeFalsyValuesFromPayload(query, ['*'])
    console.log(_query)
    return await fetchOnce<TListJobApplicationResponse>(
      `/api/jobs/${jobIdSlug}/applications/`,
      'GET',
      undefined,
      _query,
      oFetchOptions
    )
  }

  return useAsyncData<TListJobApplicationResponse>(
    `job-apps-list-${JSON.stringify(query)}`,
    fetchHandler,
    {
      // only watch if query is a ref
      watch: isRef(query) || isReactive(query) ? [query] : undefined,
      ...asyncDataOptions
    }
  )
}

function getJobApplication(jobIdSlug: string, appIdSlug: string) {
  const { fetchOnce } = useAuthFetch()
  const { data: user } = useAuth()
  if (!user.value) return null
  return fetchOnce<TLmsJobApplication>(`/api/jobs/${jobIdSlug}/applications/${appIdSlug}/`, 'GET')
}

function getJobApplicationFile(jobIdSlug: string, fileIdSlug: string) {
  const { fetchOnce } = useAuthFetch()
  const { data: user } = useAuth()
  if (!user.value) return null
  return fetchOnce<any>(`/api/jobs/${jobIdSlug}/applications/files/${fileIdSlug}/`, 'GET')
}

async function markAppVisited(companyIdSlug: string, appIdSlug: string) {
  const { fetchOnce } = useAuthFetch()
  await fetchOnce(`/api/v2/job-application-company-visited/`, 'POST', {
    company_id_slug: companyIdSlug,
    job_application_id_slug: appIdSlug
  })
  await fetchOnce(`/api/v2/job-application-staff-visited/`, 'POST', {
    job_application_id_slug: appIdSlug
  })
}

async function markAppDownloaded(companyIdSlug: string, appIdSlug: string) {
  const { fetchOnce } = useAuthFetch()
  await fetchOnce(`/api/v2/job-application-company-downloaded/`, 'POST', {
    company_id_slug: companyIdSlug,
    job_application_id_slug: appIdSlug
  })
  await fetchOnce(`api/v2/job-application-staff-downloaded/`, 'POST', {
    job_application_id_slug: appIdSlug
  })
}

function markAppNotVisited(companyVisitedIdSlug: string) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce(`/api/v2/job-application-company-visited/${companyVisitedIdSlug}/`, 'DELETE')
}

function markAppNotDownloaded(companyDownloadedIdSlug: string) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce(
    `/api/v2/job-application-company-downloaded/${companyDownloadedIdSlug}/`,
    'DELETE'
  )
}

function createJobApplication(jobIdSlug: string, payload: TCreateJobApplicationPayload) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce(`/api/jobs/${jobIdSlug}/applications/`, 'POST', payload)
}

function updateJobApplication(
  jobIdSlug: string,
  appIdSlug: string,
  payload: { relevancy_group: number | undefined; nominated: boolean | undefined }
) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce(`/api/jobs/${jobIdSlug}/applications/${appIdSlug}`, 'PUT', payload)
}

function generateCVReviewForAJob(requestBody: TNAPIBody<'/napis/v1/cv-review/for-a-job/', 'post'>) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce(
    '/napis/v1/cv-review/for-a-job/',
    'post',
    undefined,
    undefined,
    requestBody
  )
}

function generateCVReviewForAJobRole(
  requestBody: TNAPIBody<'/napis/v1/cv-review/for-a-job-role/', 'post'>
) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce(
    '/napis/v1/cv-review/for-a-job-role/',
    'post',
    undefined,
    undefined,
    requestBody
  )
}

type UseJobApplicationReturnType = {
  adminListJobApplication: typeof adminListJobApplication
  applicantListJobApplication: typeof applicantListJobApplication
  getJobApplication: typeof getJobApplication
  getJobApplicationFile: typeof getJobApplicationFile
  markAppVisited: typeof markAppVisited
  markAppDownloaded: typeof markAppDownloaded
  markAppNotVisited: typeof markAppNotVisited
  markAppNotDownloaded: typeof markAppNotDownloaded
  createJobApplication: typeof createJobApplication
  updateJobApplication: typeof updateJobApplication
  generateCVReviewForAJob: typeof generateCVReviewForAJob
  generateCVReviewForAJobRole: typeof generateCVReviewForAJobRole
}

export function useJobApplication(): UseJobApplicationReturnType {
  return {
    adminListJobApplication,
    applicantListJobApplication,
    getJobApplication,
    getJobApplicationFile,
    markAppVisited,
    markAppDownloaded,
    markAppNotVisited,
    markAppNotDownloaded,
    createJobApplication,
    updateJobApplication,
    generateCVReviewForAJob,
    generateCVReviewForAJobRole
  }
}
